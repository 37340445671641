@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400&display=swap");

body {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #061125;
}

.container-fluid {
  padding: 0;
}

.h-90 {
  height: 90%;
}

.h-88 {
  height: 88%;
}

.h-60 {
  height: 60%;
}

.h-30 {
  height: 30%;
}

.h-28 {
  height: 28%;
}

.h-27_5 {
  height: 27.5%;
}

.h-15 {
  height: 15%;
}

.h-14_5 {
  height: 14.5%;
}

.h-12 {
  height: 12%;
}

.h-10 {
  height: 10%;
}

.h-9 {
  height: 9%;
}

.w-95 {
  width: 95%;
}

.w-70 {
  width: 70%;
}

.w-45 {
  width: 45%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.bg-dark {
  background-color: #131821 !important;
}

.bg-green {
  background-color: #28a745 !important;
}

.bg-red {
  background-color: #ef534f !important;
}

.main-content {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
}

.z-100 {
  z-index: 100;
}

.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
